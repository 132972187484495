var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Screen, PanelGrid } from '@cognitranlimited/itis-web';
import { ISheetSearchPanel } from './ISheetSearchPanel';
import { ISheetHelpPanel } from './ISheetHelpPanel';
import queryString from 'query-string';
import { useISheetsModuleContext } from './iSheetsContext';
export var ISheetLookupScreen = function () {
    var navigate = useISheetsModuleContext().navigate;
    var search = queryString.parse(location.search);
    var currentQuery = typeof search.query === 'string' ? search.query : '';
    function updateQuery(query) {
        navigate(queryString.stringifyUrl({
            url: location.pathname,
            query: {
                query: query
            }
        }));
    }
    return _jsx(Screen, __assign({ className: "ISheetLookupScreen" }, { children: _jsxs(PanelGrid, { children: [_jsx(ISheetSearchPanel, { currentQuery: currentQuery, onSearch: updateQuery }), _jsx(ISheetHelpPanel, {})] }) }));
};
