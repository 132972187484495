var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, MobileTableCell, Table, useLanguageNames } from '@cognitranlimited/itis-web';
import { useISheetsModuleContext } from './iSheetsContext';
import { ISheetTranslationSelect } from './ISheetTranslationSelect';
import './ISheetSearchTableRow.scss';
export var ISheetSearchTableRow = function (_a) {
    var _b;
    var rowProps = _a.rowProps, isCompactTable = _a.isCompactTable, iSheet = _a.iSheet;
    var navigation = useISheetsModuleContext().navigation;
    var languageNames = useLanguageNames();
    var master = (_b = iSheet.match) !== null && _b !== void 0 ? _b : iSheet.translations[0];
    var translations = iSheet.match ? iSheet.translations : iSheet.translations.slice(1);
    var link = _jsx(Link, __assign({ href: navigation.document({
            id: master.id,
            documentTypeContentType: null,
            groupId: null,
            vehicle: null,
            search: null
        }), variant: "title", onClick: function (event) { return event.stopPropagation(); } }, { children: master.title }));
    return _jsx(Table.Row, __assign({ className: "ISheetSearchTableRow", rowProps: rowProps }, { children: isCompactTable
            ? _jsx(MobileTableCell, { primary: link, secondary: _jsxs("div", __assign({ className: "ISheetSearchTableRow-languages" }, { children: [languageNames === null || languageNames === void 0 ? void 0 : languageNames.getLanguageName(master.language), translations.length > 0 && _jsx(ISheetTranslationSelect, { translations: translations })] })), date: master.reference })
            : _jsxs(_Fragment, { children: [_jsxs(Table.TextCell, __assign({ className: "ISheetSearchTableRow-languages" }, { children: [link, translations.length > 0 && _jsx(ISheetTranslationSelect, { translations: translations })] })), _jsx(Table.TextCell, { children: languageNames === null || languageNames === void 0 ? void 0 : languageNames.getLanguageName(master.language) }), _jsx(Table.TextCell, { children: master.reference })] }) }));
};
