var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Expander } from '@cognitranlimited/itis-web';
import { useISheetsModuleContext } from './iSheetsContext';
import './ISheetHelpPanel.scss';
import iSheetNumberLocation1 from './images/isheet-number-location-1.png';
import iSheetNumberLocation2 from './images/isheet-number-location-2.png';
import partNumberLocation1 from './images/part-number-location-1.png';
import partNumberLocation2 from './images/part-number-location-2.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
export var ISheetHelpPanel = function () {
    var strings = useISheetsModuleContext().strings.iSheets;
    return _jsx(Expander, __assign({ className: "ISheetHelpPanel", title: strings.helpTitle }, { children: _jsxs("div", __assign({ className: "ISheetHelpPanel-content" }, { children: [_jsx("p", { children: strings.helpDescriptionPara1 }), _jsx("p", { children: strings.helpDescriptionPara2 }), _jsxs(Carousel, __assign({ className: "ISheetHelpPanel-carousel", autoPlay: true, infiniteLoop: true, interval: 5000, showThumbs: false, showStatus: false }, { children: [_jsx("img", { src: iSheetNumberLocation1, alt: strings.iSheetNumberLocationImage1 }), _jsx("img", { src: iSheetNumberLocation2, alt: strings.iSheetNumberLocationImage2 }), _jsx("img", { src: partNumberLocation1, alt: strings.partNumberLocationImage1 }), _jsx("img", { src: partNumberLocation2, alt: strings.partNumberLocationImage2 })] }))] })) }));
};
