var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonLink, Panel } from '@cognitranlimited/itis-web';
import { useISheetsModuleContext } from './iSheetsContext';
import './ISheetLookupPanel.scss';
export var ISheetLookupPanel = function () {
    var strings = useISheetsModuleContext().strings;
    return _jsxs(Panel, __assign({ className: "ISheetLookupPanel" }, { children: [_jsx(Panel.Header, { title: strings.iSheets.lookupTitle }), _jsxs(Panel.Content, { children: [_jsx("p", { children: strings.iSheets.lookupDescription }), _jsx(ButtonLink, __assign({ href: "/lookup/isheets" }, { children: strings.iSheets.lookupButton }))] })] }));
};
