var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Panel, Text, Pagination, TextBox, Button } from '@cognitranlimited/itis-web';
import { useEffect, useState } from 'react';
import { useISheetsModuleContext } from './iSheetsContext';
import './ISheetSearchPanel.scss';
import { ISheetSearchTable } from './ISheetSearchTable';
import { useISheetSearch } from './useISheetSearch';
export var ISheetSearchPanel = function (_a) {
    var currentQuery = _a.currentQuery, onSearch = _a.onSearch;
    var strings = useISheetsModuleContext().strings.iSheets;
    var _b = useISheetSearch(currentQuery), isSearching = _b.isSearching, errorMessage = _b.errorMessage, paginator = _b.paginator, results = _b.results, resetSearch = _b.resetSearch, showResults = _b.showResults;
    var _c = useState(currentQuery), nextQuery = _c[0], setNextQuery = _c[1];
    useEffect(function () {
        setNextQuery(currentQuery);
    }, [currentQuery]);
    function onQueryChange(value) {
        resetSearch();
        setNextQuery(value);
    }
    var submitForm = function (event) {
        event.preventDefault();
        onSearch(nextQuery);
        if (nextQuery === currentQuery) {
            showResults();
        }
    };
    return _jsxs(Panel, __assign({ className: "ISheetSearchPanel" }, { children: [_jsx(Panel.Header, { title: strings.searchTitle }), _jsxs(Panel.Content, { children: [_jsxs("form", __assign({ onSubmit: submitForm }, { children: [_jsx("label", __assign({ htmlFor: "ISheetSearchPanel-inputLabel" }, { children: strings.searchDescription })), _jsxs("div", __assign({ className: "ISheetSearchPanel-input" }, { children: [_jsx(TextBox, { id: "ISheetSearchPanel-inputLabel", value: nextQuery, onChange: onQueryChange }), _jsx(Button, __assign({ type: "submit", busy: isSearching, disabled: nextQuery === '' }, { children: strings.searchButton }))] })), errorMessage && _jsx("div", __assign({ className: "ISheetSearchPanel-error" }, { children: errorMessage }))] })), currentQuery && !errorMessage && results && results.elements.length > 0 && _jsxs("div", __assign({ className: "ISheetSearchPanel-results" }, { children: [_jsxs("div", __assign({ className: "ISheetSearchPanel-resultsHeader" }, { children: [_jsx(Text, __assign({ element: "p" }, { children: strings.multipleResults(currentQuery) })), _jsx(Pagination, { paginator: paginator })] })), _jsx(ISheetSearchTable, { iSheets: results.elements })] }))] })] }));
};
