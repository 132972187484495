var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Table, useElementWidth } from '@cognitranlimited/itis-web';
import { useISheetsModuleContext } from './iSheetsContext';
import { ISheetSearchTableRow } from './ISheetSearchTableRow';
import './ISheetSearchTable.scss';
export var ISheetSearchTable = function (_a) {
    var iSheets = _a.iSheets;
    var _b = useISheetsModuleContext(), strings = _b.strings, navigate = _b.navigate, navigation = _b.navigation;
    var _c = useElementWidth(), ref = _c.ref, width = _c.width;
    function getRow(rowIndex) {
        var _a;
        var iSheet = iSheets[rowIndex];
        var master = (_a = iSheet.match) !== null && _a !== void 0 ? _a : iSheet.translations[0];
        return {
            id: master.id,
            component: ISheetSearchTableRow,
            props: {
                iSheet: iSheet
            }
        };
    }
    function onClick(rowIndex) {
        var _a;
        var iSheet = iSheets[rowIndex];
        var master = (_a = iSheet.match) !== null && _a !== void 0 ? _a : iSheet.translations[0];
        navigate(navigation.document({
            id: master.id,
            documentTypeContentType: null,
            groupId: null,
            vehicle: null,
            search: null
        }));
    }
    return _jsx("div", __assign({ ref: ref, className: "ISheetSearchTable" }, { children: _jsx(Table, { columns: [
                { id: 'title', title: strings.documentGroup.titleColumn },
                { id: 'language', title: strings.iSheets.languageColumn },
                { id: 'reference', title: strings.documentGroup.referenceColumn }
            ], rowCount: iSheets.length, compactHeader: width < 600, getRow: getRow, onClick: onClick }) }));
};
