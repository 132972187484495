var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { apiBaseQuery } from '@cognitranlimited/itis-web';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import queryString from 'query-string';
export var iSheetsApi = createApi({
    reducerPath: 'iSheetsApi',
    baseQuery: apiBaseQuery(),
    endpoints: function (builder) { return ({
        searchISheets: builder.query({
            query: function (_a) {
                var partNumber = _a.partNumber, params = __rest(_a, ["partNumber"]);
                return queryString.stringifyUrl({
                    url: "/isheets/".concat(partNumber),
                    query: __assign({}, params)
                });
            },
            extraOptions: {
                authenticate: false
            }
        })
    }); }
});
export var useLazySearchISheetsQuery = iSheetsApi.useLazySearchISheetsQuery;
