export function getOptionalGlobalProperty(key) {
    var property = global.iwaProperties[key];
    return property === '' ? undefined : property;
}
export function getGlobalProperty(key) {
    var property = getOptionalGlobalProperty(key);
    if (!property) {
        throw new Error("Global property not set: ".concat(key));
    }
    return property;
}
