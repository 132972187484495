var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLanguageNames } from '@cognitranlimited/itis-web';
import { useISheetsModuleContext } from './iSheetsContext';
export var ISheetTranslationSelect = function (_a) {
    var translations = _a.translations;
    var _b = useISheetsModuleContext(), strings = _b.strings, navigate = _b.navigate, navigation = _b.navigation;
    var languageNames = useLanguageNames();
    var openDocument = function (event) {
        var documentId = event.target.value;
        if (documentId) {
            navigate(navigation.document({
                id: documentId,
                documentTypeContentType: null,
                vehicle: null,
                groupId: null,
                search: null
            }));
        }
    };
    return _jsxs("select", __assign({ onChange: openDocument, onClick: function (event) { return event.stopPropagation(); } }, { children: [_jsx("option", __assign({ value: "" }, { children: strings.documentGroup.viewInAnotherLanguage })), translations.map(function (document) { return _jsxs("option", __assign({ value: document.id }, { children: [document.title, " - ", languageNames === null || languageNames === void 0 ? void 0 : languageNames.getLanguageName(document.language)] }), document.id); })] }));
};
