var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { usePaginator } from '@cognitranlimited/itis-web';
import { useEffect, useMemo, useState } from 'react';
import { useISheetsModuleContext } from './iSheetsContext';
import { useLazySearchISheetsQuery } from './iSheetsApi';
var validSearchTermMatcher = /^([-0]*)(?=.*[A-Z-0-9])(?=.*[0-9])[A-Z0-9-]{4,64}$/i;
export function useISheetSearch(query) {
    var _a = useISheetsModuleContext(), _b = _a.strings, strings = _b.iSheets, documentSearchStrings = _b.documentSearch, navigation = _a.navigation, navigate = _a.navigate;
    var _c = useLazySearchISheetsQuery(), searchISheets = _c[0], _d = _c[1], data = _d.data, isFetching = _d.isFetching;
    var _e = useState(0), pageNumber = _e[0], setPageNumber = _e[1];
    var paginator = usePaginator(data, setPageNumber, query);
    var _f = useState(''), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = useState(false), showResults = _g[0], setShowResults = _g[1];
    useEffect(function () {
        function doSearch() {
            return __awaiter(this, void 0, void 0, function () {
                var queryMatch, result, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            queryMatch = validSearchTermMatcher.exec(query);
                            if (queryMatch === null) {
                                setErrorMessage(strings.invalidNumber);
                                return [2 /*return*/];
                            }
                            if (queryMatch[1]) {
                                setErrorMessage(strings.extranousLeadingCharacters(queryMatch[1]));
                                return [2 /*return*/];
                            }
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, searchISheets({
                                    partNumber: query,
                                    pageNumber: pageNumber,
                                    pageSize: 20
                                }).unwrap()
                                // Redirect to document if there is only one result.
                            ];
                        case 2:
                            result = _b.sent();
                            // Redirect to document if there is only one result.
                            if (result.pageNumber === 0 && result.resultCount === 1 && result.elements.length === 1 && result.elements[0].match) {
                                navigate(navigation.document({
                                    id: result.elements[0].match.id,
                                    documentTypeContentType: null,
                                    groupId: null,
                                    vehicle: null,
                                    search: null
                                }), {
                                    replace: true
                                });
                                return [2 /*return*/];
                            }
                            if (result.elements.length === 0) {
                                setErrorMessage(strings.noMatches);
                                return [2 /*return*/];
                            }
                            setErrorMessage('');
                            setShowResults(true);
                            return [3 /*break*/, 4];
                        case 3:
                            _a = _b.sent();
                            setErrorMessage(documentSearchStrings.searchError);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
        if (query) {
            doSearch();
        }
    }, [query, pageNumber]);
    return useMemo(function () { return ({
        isSearching: isFetching,
        results: showResults ? data : undefined,
        paginator: paginator,
        errorMessage: errorMessage,
        resetSearch: function () {
            setErrorMessage('');
            setShowResults(false);
        },
        showResults: function () {
            setShowResults(true);
        }
    }); }, [isFetching, showResults, data, paginator, errorMessage]);
}
